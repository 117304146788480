<template>
    <main class="error-401">
        <p class="error-401__text">У вас нет доступа <template v-if="requestedResource">к ресурсу "{{requestedResource}}"</template><template v-else>к запрашиваему ресурсу</template>.</p>
        <p class="error-401__text"> Если вы считаете, что произошла ошибка, обратитесь к админиcтратору системы.</p>
    </main>
</template>

<script>
    export default {
        name: "Error401",
        props: {
            requestedResource: {
                type: String,
                default: ""
            }
        }
    }
</script>

<style lang="scss">
    .error-401__text {
        &:not(:first-child) {
            margin-top: 10px;
        }
    }
</style>
